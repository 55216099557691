import { styled } from '@mui/material/styles';
import { Grid, Typography, SvgIcon } from '@mui/material';

import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { finanzcheck } from '@finanzcheck/teal-mui-theme';

import { AssetFile } from 'contentful';

import { ICeInfoBoxFields } from 'types/generated/contentful';
import { RichTextContent } from '../RichText/RichTextContent';

import { SPACE12, SPACE, SPACE4 } from 'lib/constants/layout';
import {
  removeBulletPoints,
  isBulletPointMacro,
} from 'lib/text-macros/textMacroHelper';
import { getBulletPointIcon } from '../RichText/RichTextContent';

const { colors } = finanzcheck;

// Color theme for the infoboxes
const colorMap: { [x: string]: { text: string; background: string } } = {
  blue: {
    text: colors.text.white,
    background: colors.primary[400],
  },
  base: {
    text: colors.text.primary,
    background: colors.background.default,
  },
};

interface WithColorSetProps {
  colorSet: 'base' | 'blue';
}

const Wrapper = styled('div')<WithColorSetProps>`
  color: ${({ colorSet }) => colorMap[colorSet].text};
  background-color: ${({ colorSet }) => colorMap[colorSet].background};
  margin-bottom: ${SPACE4};
  padding: ${SPACE4} 0;
`;

const Svg = styled('object')`
  display: block;
  max-width: ${SPACE12};
  margin: 0 auto;
`;

const Icon = styled('img')`
  max-width: ${SPACE12};
`;

const IconCellWrapper = styled('div')<WithColorSetProps>`
  height: 100%;
  width: 100%;
  padding: 0 ${SPACE};
  border-right: ${({ colorSet }) => `1px solid ${colorMap[colorSet].text}`};
`;

export type InfoBoxProps = ICeInfoBoxFields;

export const InfoBox = (props: InfoBoxProps) => {
  const { colorSet = 'base', icon, info } = props;

  const iconFile = icon.fields.file as AssetFile;
  const iconTitle = icon.fields.title?.toString() || 'Icon';

  // We are overriding how the paragraph is rendered
  // this is bit more elegant than changing the theme for the component,
  // which might have side effects when more than one info box
  // is used on the same page.
  const paragraphOptions: Options = {
    renderNode: {
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>{children}</ul>
      ),
      [BLOCKS.PARAGRAPH]: (_, children) => {
        const isBulletPoint = isBulletPointMacro((children as any)[0]);
        let bulletPointChar = '';

        children = (children as any[]).map((child) => {
          if (typeof child === 'string' && isBulletPoint) {
            bulletPointChar = child[1] as any;
            return `${removeBulletPoints(child)}`;
          }

          return child;
        });

        const bulletIcon = getBulletPointIcon(bulletPointChar);

        return (
          <Typography
            variant="body1"
            color={colorMap[colorSet].text}
            sx={{ textIndent: `-26px` }}
            ml={4}
          >
            <SvgIcon
              component={bulletIcon}
              sx={{
                verticalAlign:
                  bulletPointChar === '•' ? 'middle' : 'text-bottom',
                height: bulletPointChar === '•' ? '.3em' : '.8em',
              }}
            />
            {children}
          </Typography>
        );
      },
    },
  };

  return (
    <>
      <Wrapper colorSet={colorSet}>
        <Grid container>
          <Grid item xs={12} md={1}>
            <IconCellWrapper colorSet={colorSet}>
              <Svg data={iconFile.url} type="image/svg+xml" width="48">
                <Icon
                  src={iconFile.url}
                  width="48"
                  alt={iconTitle}
                  loading="lazy"
                />
              </Svg>
            </IconCellWrapper>
          </Grid>
          <Grid
            item
            xs={10}
            md={11}
            sx={{ paddingRight: '8px', paddingLeft: '8px' }}
          >
            <RichTextContent document={info} options={paragraphOptions} />
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
};
