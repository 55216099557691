import React from 'react';
import Image from 'next/image';

import { Document, BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Typography, SvgIcon } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';

import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { ContentfulItem } from '../ContentfulItem';
import {
  removeBulletPoints,
  isBulletPointMacro,
  expandTextMacros,
} from 'lib/text-macros/textMacroHelper';

export const BULLETPOINTS: { [key: string]: React.ElementType } = {
  '•': CircleIcon,
  '+': AddIcon,
  '-': RemoveIcon,
  '✓': CheckIcon,
};

export const getBulletPointIcon = (bulletPoint: string) => {
  return BULLETPOINTS[bulletPoint] || null;
};

// Note: The renderText method will change all newlines and change them into br tags.
// If that is not done, react will choke on some browsers as the newline will cause a
// plethora of hydration errors as some browser will filter them out and then you have
// a gross mismatch of content.
const defaultOptions: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children) => (
      <Typography
        variant="h1"
        color="text.blue"
        mb={'1rem'} // Fixed inline due to dicrepancy in theme
        sx={{ lineHeight: '2.19rem' }} // Fixed inline due to dicrepancy in theme
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (_, children) => (
      <Typography variant="h2" color="text.blue">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (_, children) => (
      <Typography variant="h3" color="text.blue">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_, children) => (
      <Typography variant="h4" color="text.blue">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_, children) => (
      <Typography variant="h5" color="text.blue">
        {children}
      </Typography>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => {
      const isBulletPoint = isBulletPointMacro((children as any)[0]);
      let bulletPointChar = '';

      if (!isBulletPoint) {
        return (
          <Typography variant="body1" color="text.primary">
            {children}
          </Typography>
        );
      }

      children = (children as any[]).map((child) => {
        if (typeof child === 'string' && isBulletPoint) {
          bulletPointChar = child[1] as any;
          return `${removeBulletPoints(child)}`;
        }

        return child;
      });

      const bulletIcon = getBulletPointIcon(bulletPointChar);

      return (
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ textIndent: `-26px` }}
          ml={4}
        >
          <SvgIcon
            component={bulletIcon}
            sx={{
              verticalAlign: bulletPointChar === '•' ? 'middle' : 'text-bottom',
              height: bulletPointChar === '•' ? '.3em' : '.8em',
            }}
          />
          {children}
        </Typography>
      );
    },
    [BLOCKS.UL_LIST]: (_, children) => (
      <ul style={{ listStyle: 'none', paddingLeft: 0 }}>{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (_, children) => <ol>{children}</ol>,
    [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
    [BLOCKS.EMBEDDED_ASSET]: (node, _children) => {
      const item = node.data.target;
      const width = item.fields.file.details.image.width || 1;
      const height = item.fields.file.details.image.height || 1;

      return (
        <Image
          src={`https://${item.fields.file.url}`}
          height={height}
          width={width}
          alt={item.fields.description}
          loading="lazy"
          priority={false}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, _children) => {
      // Map inline entry to React component
      const item = node.data.target;
      return <ContentfulItem {...item} />;
    },
    [INLINES.EMBEDDED_ENTRY]: (node, _children) => {
      // Map inline entry to React component
      const item = node.data.target;
      // Be careful, inline entries cannot have DIVs in them
      // as divs are not legal as childs of <p> tags
      // For this we add the inline flag so that we can take care
      // in the component to not render a div but <> or span
      return <ContentfulItem {...item} isInline={true} />;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const isExternalLink =
        node.data.uri.startsWith('http') &&
        !node.data.uri.includes('finanzcheck.de');
      if (isExternalLink) {
        return (
          <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      }

      return <a href={node.data.uri}>{children}</a>;
    },
  },

  renderText: (text) => {
    // Replace newlines with br tags
    return text.split('\n').reduce((children: any, textSegment, index) => {
      return [
        ...children,
        index > 0 && <br key={index} />,
        expandTextMacros(textSegment),
      ];
    }, []);
  },
};

export const RichTextContent = ({
  document,
  options,
}: {
  document?: Document;
  options?: Options;
}) => {
  if (!document) return <></>;

  const mergedOptions = {
    renderNode: {
      ...defaultOptions.renderNode,
      ...options?.renderNode,
    },
  };
  const reactComponents = documentToReactComponents(document, mergedOptions);
  return <>{reactComponents}</>;
};
